import Amplify, { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { PutObjectRequest } from 'aws-sdk/clients/s3';

import {
  AWS_ADMIN_GROUP_NAME,
  AWS_BUCKET_NAME,
  AWS_CLIENT_ID,
  AWS_COGNITO_REGION,
  AWS_IDENTITY_POOL_ID,
  AWS_USER_POOL_ID,
  USER_CUSTOM_ATTRIBUTES,
} from '../utils/constants';
import { User } from '../types';

Amplify.configure({
  Auth: {
    region: AWS_COGNITO_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_CLIENT_ID,
  },
});

const COGNITO_CUSTOM_ATTRIBUTE_FIELD = 'custom:attributes';
let S3: AWS.S3;
let cognitoIdentityServiceProvider: AWS.CognitoIdentityServiceProvider;

const PROVIDER_NAME = `cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOL_ID}`;

const mapCognitoUserToUser = (
  {
    Username,
    Enabled,
    UserStatus,
    Attributes,
  }: AWS.CognitoIdentityServiceProvider.UserType,
  isAdmin: boolean
): User => {
  const attributes = Attributes.reduce(
    (acc, { Name, Value }) => {
      acc[Name] = Value;
      return acc;
    },
    { email: '', phone_number: '', sub: '' }
  );

  const customAttributes = JSON.parse(
    attributes[COGNITO_CUSTOM_ATTRIBUTE_FIELD] || '{}'
  );

  return {
    id: Username,
    username: Username,
    enabled: Enabled,
    status: UserStatus,
    isAdmin,
    email: attributes.email,
    phone_number: attributes.phone_number,
    sub: attributes.sub,
    ...customAttributes,
    password: '',
    userType: isAdmin ? 'admin' : customAttributes.userType || 'gasAgent',
  };
};

export const getCurrentUser = () => {
  return new Promise<any>((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((cognitoUser) => {
        cognitoUser.getSession((err, result) => {
          if (err || !result) {
            reject(err);
            return;
          }

          if (result) {
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: AWS_IDENTITY_POOL_ID,
              Logins: {
                [PROVIDER_NAME]: result.getIdToken().getJwtToken(),
              },
            });
            S3 = new AWS.S3({
              apiVersion: '2006-03-01',
              params: { Bucket: AWS_BUCKET_NAME },
            });

            cognitoIdentityServiceProvider =
              new AWS.CognitoIdentityServiceProvider();

            resolve(cognitoUser);
          }
        });
      })
      .catch((error) => reject(error));
  });
};

const getAdminUsers = () =>
  new Promise<string[]>((resolve, reject) => {
    const listUsersInAdminGroupRequest = {
      UserPoolId: AWS_USER_POOL_ID,
      GroupName: AWS_ADMIN_GROUP_NAME,
    };

    cognitoIdentityServiceProvider.listUsersInGroup(
      listUsersInAdminGroupRequest,
      (err, adminUserData) => {
        if (err) {
          reject(err.message);
        }
        let adminUsers: string[] = [];
        if (adminUserData) {
          adminUsers = adminUserData.Users.map(({ Username }) => Username);
        }
        resolve(adminUsers);
      }
    );
  });

const awsGetUser = (username: string) =>
  new Promise<AWS.CognitoIdentityServiceProvider.AdminGetUserResponse>(
    (resolve, reject) => {
      const getUserRequest = {
        UserPoolId: AWS_USER_POOL_ID,
        Username: username,
      };

      cognitoIdentityServiceProvider.adminGetUser(
        getUserRequest,
        (err, user) => {
          if (err) {
            reject(err.message);
            return;
          }
          resolve(user);
        }
      );
    }
  );

export const getUser = async (username: string) => {
  const awsUser = await awsGetUser(username);
  const adminUsers = await getAdminUsers();
  return mapCognitoUserToUser(
    { ...awsUser, Attributes: awsUser.UserAttributes },
    adminUsers.includes(awsUser.Username)
  );
};

export const enableUser = (username: string) => {
  const enableUserRequest = {
    UserPoolId: AWS_USER_POOL_ID,
    Username: username,
  };

  return new Promise<void>((resolve, reject) => {
    cognitoIdentityServiceProvider.adminEnableUser(enableUserRequest, (err) => {
      if (err) {
        reject(err.message);
        return;
      }
      resolve();
    });
  });
};

export const disableUser = (username: string) => {
  const disableUserRequest = {
    UserPoolId: AWS_USER_POOL_ID,
    Username: username,
  };

  return new Promise<void>((resolve, reject) => {
    cognitoIdentityServiceProvider.adminDisableUser(
      disableUserRequest,
      (err) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve();
      }
    );
  });
};

export const deleteUser = (username: string) => {
  const deleteUserRequest = {
    UserPoolId: AWS_USER_POOL_ID,
    Username: username,
  };

  return new Promise<void>((resolve, reject) => {
    cognitoIdentityServiceProvider.adminDeleteUser(deleteUserRequest, (err) => {
      if (err) {
        reject(err.message);
        return;
      }
      resolve();
    });
  });
};

export const awsListUsers = (userPoolId: string, paginationToken?: string) =>
  new Promise<AWS.CognitoIdentityServiceProvider.ListUsersResponse>(
    (resolve, reject) => {
      const listUsersRequest = {
        UserPoolId: userPoolId,
        PaginationToken: paginationToken,
      };

      cognitoIdentityServiceProvider.listUsers(
        listUsersRequest,
        (err, userData) => {
          if (err) {
            reject(err.message);
            return;
          }

          resolve(userData);
        }
      );
    }
  );

export const getUserList = async () => {
  const adminUsers = await getAdminUsers();
  const Users: AWS.CognitoIdentityServiceProvider.UsersListType = [];
  let paginationToken: string;
  do {
    // eslint-disable-next-line no-await-in-loop
    const awsUsers = await awsListUsers(AWS_USER_POOL_ID, paginationToken);
    if (awsUsers) {
      paginationToken = awsUsers.PaginationToken;
      Users.push(...(awsUsers.Users || []));
    }
  } while (paginationToken);

  return Users.map((cognitoUser) =>
    mapCognitoUserToUser(cognitoUser, adminUsers.includes(cognitoUser.Username))
  );
};

const changeAdminPermission = (
  username: string,
  action: 'Add' | 'Remove',
  callback: (error: AWS.AWSError) => void
) => {
  const adminGroupRequest = {
    UserPoolId: AWS_USER_POOL_ID,
    Username: username,
    GroupName: AWS_ADMIN_GROUP_NAME,
  };

  if (action === 'Add') {
    cognitoIdentityServiceProvider.adminAddUserToGroup(
      adminGroupRequest,
      (error) => {
        callback(error);
      }
    );
  } else {
    cognitoIdentityServiceProvider.adminRemoveUserFromGroup(
      adminGroupRequest,
      (error) => {
        callback(error);
      }
    );
  }
};

const getCustomAttributes = (user: User) => {
  const userAttributes: { [attr: string]: string | boolean | string[] } = {
    userType: user.userType,
    fullName: user.fullName,
  };

  if (user.userType === 'gasAgent') {
    USER_CUSTOM_ATTRIBUTES.forEach(({ field }) => {
      if (user[field]) {
        userAttributes[field] = user[field];
      }
    });
  }

  return JSON.stringify(userAttributes);
};

export const createUser = (user: User, suppressEmail?: boolean) => {
  return new Promise<User>((resolve, reject) => {
    const newUserRequest = {
      MessageAction: undefined,
      UserPoolId: AWS_USER_POOL_ID,
      Username: user.username,
      TemporaryPassword: user.password,
      DesiredDeliveryMediums: ['EMAIL'],
      UserAttributes: [
        {
          Name: COGNITO_CUSTOM_ATTRIBUTE_FIELD,
          Value: getCustomAttributes(user),
        },
      ],
    };

    if (suppressEmail) {
      newUserRequest.MessageAction = 'SUPPRESS';
    }

    if (user.email) {
      newUserRequest.UserAttributes.push({
        Name: 'email_verified',
        Value: 'true',
      });
      newUserRequest.UserAttributes.push({
        Name: 'email',
        Value: user.email,
      });
    }

    if (user.phone_number) {
      newUserRequest.UserAttributes.push({
        Name: 'phone_number_verified',
        Value: 'true',
      });
      newUserRequest.UserAttributes.push({
        Name: 'phone_number',
        Value: user.phone_number,
      });
    }

    cognitoIdentityServiceProvider.adminCreateUser(
      newUserRequest,
      (err, data) => {
        if (err) {
          reject(err.message);
          return;
        }

        if (user.userType === 'admin') {
          changeAdminPermission(user.username, 'Add', (error) => {
            // if it fails to add the new user to Admin group. The creator can edit later.
            resolve(mapCognitoUserToUser(data.User, error === undefined));
          });
        } else {
          resolve(mapCognitoUserToUser(data.User, false));
        }
      }
    );
  });
};

// user.isAdmin !== formValues.isAdmin ? formValues.isAdmin : undefined
export const updateUser = (user: User) => {
  return new Promise<void>((resolve, reject) => {
    const updateUserRequest = {
      UserPoolId: AWS_USER_POOL_ID,
      Username: user.username,
      UserAttributes: [
        {
          Name: COGNITO_CUSTOM_ATTRIBUTE_FIELD,
          Value: getCustomAttributes(user),
        },
      ],
    };

    if (user.email) {
      updateUserRequest.UserAttributes.push({
        Name: 'email_verified',
        Value: 'true',
      });
      updateUserRequest.UserAttributes.push({
        Name: 'email',
        Value: user.email,
      });
    }

    if (user.phone_number) {
      updateUserRequest.UserAttributes.push({
        Name: 'phone_number_verified',
        Value: 'true',
      });
      updateUserRequest.UserAttributes.push({
        Name: 'phone_number',
        Value: user.phone_number,
      });
    }

    cognitoIdentityServiceProvider.adminUpdateUserAttributes(
      updateUserRequest,
      (err) => {
        if (err) {
          reject(err.message);
          return;
        }

        // there is a change in admin right
        if (
          (user.isAdmin && user.userType !== 'admin') ||
          (!user.isAdmin && user.userType === 'admin')
        ) {
          changeAdminPermission(
            user.username,
            user.userType === 'admin' ? 'Add' : 'Remove',
            (error) => {
              resolve();
            }
          );
          return;
        }

        resolve();
      }
    );
  });
};

export const resetUserPassword = (username: string, newPassword: string) => {
  return new Promise<void>((resolve, reject) => {
    cognitoIdentityServiceProvider.adminSetUserPassword(
      {
        Username: username,
        Password: newPassword,
        UserPoolId: AWS_USER_POOL_ID,
        Permanent: true,
      },
      (err) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve();
      }
    );
  });
};

export const changeCurrentUserPassword = (
  accessToken: string,
  currentPassword: string,
  newPassword: string
) => {
  return new Promise<void>((resolve, reject) => {
    cognitoIdentityServiceProvider.changePassword(
      {
        AccessToken: accessToken,
        PreviousPassword: currentPassword,
        ProposedPassword: newPassword,
      },
      (err) => {
        if (err) {
          reject(err.message);
          return;
        }
        resolve();
      }
    );
  });
};

export const getS3ObjectList = () => {
  return new Promise<AWS.S3.ObjectList>((resolve, reject) => {
    S3.listObjects(null, (err, data) => {
      if (err) {
        reject(err.message);
        return;
      }

      if (data) {
        resolve(data.Contents);
      }
    });
  });
};

export const deleteS3Object = (name: string) => {
  return new Promise<void>((resolve, reject) => {
    S3.deleteObject({ Bucket: AWS_BUCKET_NAME, Key: name }, (err) => {
      if (err) {
        reject(err.message);
      } else {
        resolve();
      }
    });
  });
};

export const uploadS3Object = (name: string, file: File) => {
  return new Promise<AWS.S3.ManagedUpload.SendData>((resolve, reject) => {
    const uploadParams: PutObjectRequest = {
      Bucket: AWS_BUCKET_NAME,
      Key: name,
      Body: file,
      ACL: 'public-read',
    };

    S3.upload(uploadParams, (err, data) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(data);
      }
    });
  });
};
