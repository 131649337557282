import Amplify, { Auth } from 'aws-amplify';

import {
  AWS_CLIENT_ID,
  AWS_COGNITO_REGION,
  AWS_USER_POOL_ID,
} from './constants';

Amplify.configure({
  Auth: {
    region: AWS_COGNITO_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_CLIENT_ID,
  },
});

export const signIn = (username: string, password: string) =>
  Auth.signIn(username.toLowerCase(), password);

export const signOut = () => Auth.signOut();

export const updatePassword = (
  user: any,
  password: string,
  requiredAttributes?: any
) => Auth.completeNewPassword(user, password, requiredAttributes);

export const forgotPassword = (username: string) =>
  Auth.forgotPassword(username);

export const resetPassword = (
  username: string,
  code: string,
  newPassword: string
) => Auth.forgotPasswordSubmit(username, code, newPassword);

export const getUserPermission = async () => {
  const user = (await Auth.currentSession()).getIdToken().payload;
  return JSON.parse(user['custom:app-permissions'] || '{}');
};
