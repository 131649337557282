import { createTheme } from '@material-ui/core/styles';
import colors from '../styles/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: '#333',
      main: '#333',
      dark: '#333',
      contrastText: '#0e1d28',
    },
    secondary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
      contrastText: '#0e1d28',
    },
  },
  typography: {
    fontFamily: ['Linde Dax', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 200,
    fontWeightMedium: 400,
  },
});

theme.overrides = {
  MuiDivider: {
    root: {
      backgroundColor: '#E0E0E0',
    }
  },
  MuiButton: {
    root: {
      fontSize: 14,
      lineHeight: 1.42857143,
      fontWeight: 'bold',
      textTransform: 'none',
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        opacity: '0.8',
      },
    },
    contained: {
      border: '1px solid #d7dadb',
      borderRadius: '4px',
      background:
        'linear-gradient(to bottom, #ffffff 0, #ebe9e2 76%, #ebe3cd 100%)',
    },
    outlined: {
      borderColor: colors.danger,
    },
    containedPrimary: {
      color: '#e3001b',
    },
    containedSecondary: {
      color: '#000000',
    },
    disabled: {
      color: '#ccc',
      boxShadow: 'none',
      opacity: '0.65',
    },
  },
  MuiTypography: {
    colorPrimary: {
      color: '#333',
    },
    colorTextPrimary: {
      color: '#333',
    },
    colorSecondary: {
      color: '#FFF',
    },
    colorTextSecondary: {
      color: '#FFF',
    },
    colorError: {
      color: '#e3001b',
    },
    h1: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 50,
      },
    },
    h2: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 35,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 40,
      },
    },
    h3: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 25,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
    },
    h4: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    h5: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
    },
    h6: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
    body1: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    body2: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
    subtitle1: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
    subtitle2: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 6,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 8,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 10,
      },
    },
  },
};

export default theme;
