import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

interface LoadingIndicatorProps {
  loading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ loading }) => {
  if (!loading) return null;
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      left={0}
      top={0}
      zIndex={100000}
      bgcolor="rgba(255, 255, 255, 0.2)"
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};
export default LoadingIndicator;
