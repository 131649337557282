import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import { signOut } from '../../utils/auth';
import { ROUTES } from '../../utils/constants';

interface ProfileMenuProps {
  isAdmin: boolean;
}

const ProfileMenu = ({ isAdmin }: ProfileMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'profile' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        onMouseEnter={() => setOpen(true)}
      >
        <Avatar />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="profile-menu" onKeyDown={handleListKeyDown}>
                  {isAdmin && (
                    <MenuItem
                      onClick={() => {
                        navigate(ROUTES.ADMIN);
                        handleToggle();
                      }}
                    >
                      <ListItemText primary="Admin" />
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      navigate(ROUTES.PROFILE);
                      handleToggle();
                    }}
                  >
                    <ListItemText primary="My Profile" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      signOut();
                      navigate('/');
                      handleToggle();
                    }}
                  >
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ProfileMenu;