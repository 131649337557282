import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby-theme-material-ui';
import { useLocation } from '@reach/router';

import MobileMenu from './MobileMenu';
import BocLogo from '../BocLogo';
import ProfileMenu from './ProfileMenu';
// import Search from '../Search';
import { ROUTES } from '../../utils/constants';
import colors from '../../styles/colors';

const AUTHENTICATED_TOP_NAV_ITEMS = [
  // {
  //  link: '/home/help',
  //  label: 'Help',
  // },
  // {
  //   link: '/home/feedback',
  //   label: 'Feedback',
  // },
  // {
  //  link: '/home/form/contact',
  //  label: 'Contact Us',
  // },
  {
    link: ROUTES.PROFILE,
    label: 'Profile',
  },
  {
    link: '/home/logout',
    label: 'Logout',
  },
];

const UNAUTHENTICATED_TOP_NAV_ITEMS = [
  {
    link: '/auth',
    label: 'Login',
  },
];

const MAIN_NAV_ITEMS = [
  // {
  //   link: '/home/dashboard',
  //   label: 'My Dashboard',
  // },
  // {
  //   link: '/home/customer-lookup',
  //   label: 'Customer Lookup',
  // },
  {
    link: '/home/self-service',
    label: 'Self Service',
  },
  // {
  //  link: '/home/troubleshooting',
  //  label: 'Troubleshooting',
  // },
  {
    link: '/home/knowledgebase',
    label: 'Knowledgebase',
  },
  {
    link: ROUTES.COMMUNICATIONS,
    label: 'Communications',
  },
  // {
  //   link: '/home/training',
  //   label: 'Training',
  // },
  // {
  //   link: '/home/safety',
  //   label: 'Safety',
  // },
];

const useStyles = makeStyles<{}, { opacity: number }>({
  path: {
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold',
      borderBottom: 'solid 1px #333',
    },
  },
  appBar: ({ opacity }) => ({
    // boxShadow: '0 2px 20px 0 rgba(21, 24, 36, 0.16)',
    backgroundColor: `rgba(255, 255, 255, ${opacity})`,
  }),
  toolbar: {
    paddingBottom: '12px',
  },
});

interface HeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

const Header = ({ isAuthenticated, isAdmin }: HeaderProps) => {
  const { pathname } = useLocation();
  const [opacity, setOpacity] = useState(0);
  const classes = useStyles({ opacity });

  useEffect(() => {
    const handleScroll = () => {
      const newOpacityValue = Math.min(window.scrollY / 100, 1);
      setOpacity(newOpacityValue);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AppBar
      position="sticky"
      className={classes.appBar}
      elevation={Math.round(opacity * 3)}
    >
      <Toolbar className={classes.toolbar}>
        <Box p={2} pb={4 - (2 * opacity)} width="100%">
          <Hidden smUp>
            <MobileMenu
              paths={isAuthenticated ? [...MAIN_NAV_ITEMS, ...AUTHENTICATED_TOP_NAV_ITEMS] : UNAUTHENTICATED_TOP_NAV_ITEMS}
              canSearch={false/*isAuthenticated*/}
            />
          </Hidden>
          <Hidden only="xs">
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                container
                direction="row"
                spacing={1}
                justifyContent="flex-end"
              >
                {(isAuthenticated ? [] : UNAUTHENTICATED_TOP_NAV_ITEMS).map(({ link, label }) => (
                  <Grid item key={link}>
                    <Link to={link}>
                      <Box
                        borderBottom={pathname.startsWith(link) ? 1 : 0}
                        className={classes.path}
                      >
                        <Typography variant="body2">
                          {label.toUpperCase()}
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                ))}
                {isAuthenticated && (
                  <Grid item>
                    <ProfileMenu isAdmin={isAdmin} />
                  </Grid>
                )}
              </Grid>
              {/*isAuthenticated && (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Box width="400px">
                      <Search indexName={ALGOLIA_COMMON_INDEX_NAME} />
                    </Box>
                  </Grid>
                </Grid>
              )*/}
              <Grid item container direction="row" spacing={2}>
                <Grid item>
                  <Link to="/">
                    <BocLogo />
                  </Link>
                </Grid>
                <Grid item xs>
                  <Box
                    width="100%"
                    height="100%"
                    borderBottom={`2px solid ${colors.grey}`}
                    display="flex"
                    alignItems="center"
                    pl={2}
                  >
                    <Typography variant="h2" color="primary">
                      MY AGENT PORTAL
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {isAuthenticated && (
                <Grid item>
                  <Box ml={10} mt={2}>
                    <Grid container direction="row" spacing={2} justifyContent="center">
                      {MAIN_NAV_ITEMS.map(({ link, label }) => (
                        <Grid item key={link}>
                          <Link to={link}>
                            <Box
                              borderBottom={pathname.startsWith(link) ? 1 : 0}
                              className={classes.path}
                            >
                              <Typography variant="h4">{label}</Typography>
                            </Box>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
