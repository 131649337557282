const colors = {
  primary: '#333333',
  secondary: '#FFFFFF',
  primaryLighter: '#F1F3F5',
  white: '#FFFFFF',
  danger: '#e3001b',
  mainBg: '#F5F0E6',
  grey: '#90969B',
  lightGrey: '#E0E0E0',
};

export default colors;
