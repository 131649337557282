import React from 'react';
import {
  connectSearchBox,
  connectHits,
  Highlight,
} from 'react-instantsearch-dom';
import { HitsProvided, SearchBoxProvided } from 'react-instantsearch-core';
import Typography from '@material-ui/core/Typography';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';

interface Hit {
  slug: string;
  title: string;
  content: string;
}

interface HitsProps extends HitsProvided<Hit>, SearchBoxProvided {
  anchorEl: HTMLElement | null;
}

const Hits = connectHits(({ hits, anchorEl, refine }: HitsProps) => (
  <Popper
    open
    anchorEl={anchorEl}
    transition
    disablePortal
    placement="bottom-start"
    style={{ width: anchorEl.clientWidth, border: 'solid 1px #000' }}
  >
    {({ TransitionProps }) => (
      <Grow {...TransitionProps} style={{ transformOrigin: 'top left' }}>
        {hits.length > 0 ? (
          <MenuList style={{ backgroundColor: '#fff' }}>
            {hits.map((hit) => (
              <MenuItem key={hit.slug}>
                <Link to={hit.slug} onClick={() => refine('')}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h6">{hit.title}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="overline"
                        style={{ fontWeight: 'inherit' }}
                      >
                        <Highlight
                          attribute="content"
                          hit={hit}
                          tagName="mark"
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <Typography variant="body1" color="primary" component="div">
            <Box p={2} fontWeight="bold">
              There is no result
            </Box>
          </Typography>
        )}
      </Grow>
    )}
  </Popper>
));

export default connectSearchBox((props: HitsProps) => <Hits {...props} />);
