import * as Yup from 'yup';

export const CMS_API_KEY = process.env.GATSBY_CMS_API_KEY;

export const CMS_DATASET = process.env.GATSBY_CMS_DATASET;

export const CMS_PROJECT_ID = process.env.GATSBY_CMS_PROJECT_ID;

export const ALGOLIA_COMMON_INDEX_NAME =
  process.env.GATSBY_ALGOLIA_COMMON_INDEX_NAME;

export const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID;

export const ALGOLIA_SEARCH_KEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY;

export const AWS_USER_POOL_ID = process.env.GATSBY_AWS_USER_POOL_ID;

export const AWS_CLIENT_ID = process.env.GATSBY_AWS_CLIENT_ID;

export const AWS_COGNITO_REGION = process.env.GATSBY_AWS_COGNITO_REGION;

export const AWS_IDENTITY_POOL_ID = process.env.GATSBY_AWS_IDENTITY_POOL_ID;

export const AWS_BUCKET_NAME = process.env.GATSBY_AWS_BUCKET_NAME;

export const AWS_ADMIN_GROUP_NAME = process.env.GATSBY_AWS_ADMIN_GROUP_NAME;

export const API_URL = process.env.GATSBY_API_URL;

export const ROUTES = {
  LOGIN: '/auth',
  FORGOT_PASSWORD: '/auth/forgot-password',
  ADMIN: '/admin',
  ADMIN_FILE_MANAGEMENT: '/admin/file-management',
  ADMIN_FILE_MANAGEMENT_UPLOAD: '/admin/file-management/upload',
  ADMIN_USER_MANAGEMENT: '/admin/user-management',
  ADMIN_USER_DETAIL: '/admin/user-management/user',
  ADMIN_USER_IMPORT: '/admin/user-management/import',
  HOME: '/home',
  KNOWLEDGEBASE: '/home/knowledgebase',
  SELF_SERVICE: '/home/self-service',
  COMMUNICATIONS: '/home/communications',
  COMMUNICATIONS_TAG: '/home/communications/tag',
  FORM: '/home/form',
  PROFILE: '/home/profile',
};

export const VALIDATION_SCHEMA = {
  usernameRequired: Yup.string().required('Please enter your username.'),
  passwordRequired: Yup.string().required('Please enter your password.'),
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .required('New password required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Please confirm password'),
};

export const USER_CUSTOM_ATTRIBUTES = [
  { field: 'type', title: 'Type', options: ['GA', 'EP', 'GAEP', 'PLANT'] },
  { field: 'segmentation', title: 'Segmentation' },
  { field: 'class', title: 'Class', options: ['Large', 'Regular'] },
  { field: 'managedBy', title: 'Managed By' },
  { field: 'plantNumber', title: 'Plant Number' },
  { field: 'plantName', title: 'Plant Name' },
  {
    field: 'isEquipmentPartner',
    title: 'Is Equipment Partner',
    options: ['Yes', 'No'],
  },
  {
    field: 'equipmentPartnerAccountNumber',
    title: 'Equipment Partner Account Number',
  },
  // { field: 'abn', title: 'ABN' },
  { field: 'telephone', title: 'Telephone' },
  { field: 'streetAddress', title: 'Street Address' },
  { field: 'suburb', title: 'Suburb' },
  { field: 'state', title: 'State' },
  { field: 'postCode', title: 'Post Code' },
  /*
  { field: 'weekdayOpeningHours', title: 'Weekday Opening Hours' },
  { field: 'weekendOpeningHours', title: 'Weekend Opening Hours' },
  { field: 'businessOwnerName', title: 'Business Owner Name' },
  { field: 'businessManagerName', title: 'Business Manager Name' },
  {
    field: 'productSetup',
    title: 'Product Setup',
    multiple: true,
    options: [
      'Industrial',
      'Hospitality',
      'Medical',
      'S4 Medical',
      'LPG',
      'Envirosol',
      'Refrigerant',
    ],
  },
  {
    field: 'remunerationSegment',
    title: 'Renumeration Segment',
  },
  { field: 'handlingZone', title: 'Handling Zone' },
  { field: 'partnerRegion', title: 'Partner Region' },
  { field: 'region', title: 'Region' },
  {
    field: 'powerZone',
    title: 'Power Zone',
  },
  { field: 'industrialStructure', title: 'Industrial Structure' },
  { field: 'gasGearManager', title: 'Gas & Gear Manager' },
  { field: 'industrialSalesManager', title: 'Industrial Sales Manager' },
  { field: 'industrialGeneralManager', title: 'Industrial General Manager' },
  */
];
