import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';

import './styles.scss';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from '../../utils/constants';

import Hits from './Hits';
import Results from './Results';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

interface SearchProps {
  indexName: string;
}

const Search = ({ indexName }: SearchProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <SearchBox
        translations={{
          placeholder: 'Search site here...',
        }}
      />
      <div
        ref={(e) => {
          setAnchorEl(e);
        }}
      >
        <Results>
          <Hits anchorEl={anchorEl} />
        </Results>
      </div>
    </InstantSearch>
  );
};

export default Search;
