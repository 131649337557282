import cmsSanityClient from '@sanity/client';
import axios from 'axios';

import { CMS_API_KEY, CMS_DATASET, CMS_PROJECT_ID, API_URL } from '../utils/constants';

export default cmsSanityClient({
  projectId: CMS_PROJECT_ID,
  dataset: CMS_DATASET,
  useCdn: true,
  token: CMS_API_KEY,
});

export const httpClient = axios.create({
  baseURL: API_URL,
  timeout: 5000,
});

export const httpClientWithoutToken = axios.create({
  baseURL: API_URL,
  timeout: 5000,
});
