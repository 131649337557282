import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import 'instantsearch.css/themes/reset.css';

import Header from './Header';
import Footer from './Footer';
import {
  useAuthentication,
  AuthenticationProvider,
} from '../../hooks/useAuthentication';
import LoadingIndicator from '../LoadingIndicator';

const Layout = ({ children }) => {
  const { user, isAdmin, initialised } = useAuthentication();
  if (user) {
    if (window.gtag) {
      window.gtag('config', 'G-6X4L3KBBBJ', {
        user_id: user.attributes.sub,
      });
      window.gtag('set', 'user_properties', {
        agent_id: user.attributes.sub,
      });
    }
  }
  if (!initialised) return <LoadingIndicator loading />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={0}
      minHeight="100vh"
      width="100vw"
      bgcolor="#FFF"
    >
      <CssBaseline />
      <Header isAuthenticated={user} isAdmin={isAdmin} />
      <Box
        p={{ xs: 2, md: 4 }}
        width="100%"
        maxWidth={1600}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        bgcolor="#FFF"
      >
        {children}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        width="100%"
        justifyContent="flex-end"
        bgcolor="#FFF"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default ({ children }) => (
  <AuthenticationProvider>
    <Layout>{children}</Layout>
  </AuthenticationProvider>
);
