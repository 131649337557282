import { useEffect, useState, useMemo } from 'react';
import constate from 'constate';
import { Hub } from 'aws-amplify';
import AWS from 'aws-sdk';

import { AWS_COGNITO_REGION, AWS_IDENTITY_POOL_ID } from '../utils/constants';
import { isBrowser } from '../utils';
import { getCurrentUser } from '../client/aws';
import { httpClient } from '../client';

if (isBrowser) {
  AWS.config.update({
    region: AWS_COGNITO_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWS_IDENTITY_POOL_ID,
    }),
  });
}

const useAuthenticationContext = () => {
  const [initialised, setInitialised] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (err) {
        console.error('Not authenticated');
      }

      setInitialised(true);
    })();

    const AuthListner = ({ payload }) => {
      switch (payload.event) {
        case 'signOut':
          setUser(null);
          break;
        case 'signIn':
          setUser(payload.data);
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', AuthListner);
    return () => Hub.remove('auth', AuthListner);
  }, []);

  useEffect(() => {
    if (user?.signInUserSession.idToken.jwtToken) {
      httpClient.defaults.headers.common = { Authorization: user?.signInUserSession.idToken.jwtToken }
    }
  }, [user]);

  const isAdmin = useMemo(() => {
    if (user) {
      const userGroups: string[] =
        user.signInUserSession.accessToken.payload['cognito:groups'] || [];
      return userGroups.includes('admin');
    }
    return false;
  }, [user]);

  return {
    user,
    userAttributes: {
      ...(user?.attributes || {}),
      ...JSON.parse(user?.attributes ? user.attributes['custom:attributes'] : '{}'),
    },
    isAdmin,
    initialised,
  };
};

export const [AuthenticationProvider, useAuthentication] = constate(
  useAuthenticationContext
);
export default useAuthentication;
