import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@material-ui/core/Box';

import colors from '../../styles/colors';

const query = graphql`
  query {
    allSanityMisc(filter: { key: { current: { eq: "copyright" } } }, limit: 1) {
      nodes {
        content
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(query);

  const content = data?.allSanityMisc.nodes[0]?.content;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      width="100%"
      bgcolor={colors.mainBg}
      py={{ xs: 2, md: 4 }}
      px={{ xs: 1, md: 2 }}
    >
      {content}
    </Box>
  );
};

export default Footer;
