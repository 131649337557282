import imageUrlBuilder from '@sanity/image-url';

import client from '../client';
import { IKnowledgebaseArticle } from '../types';

export const isBrowser = typeof window !== 'undefined';

export const getTimeString = (time: string) => {
  return new Date(time).toLocaleDateString();
};

const builder = imageUrlBuilder(client);

export const urlForImage = (source) => {
  return builder.image(source);
};

export const getKnowledgebaseArticleType = ({
  content,
  pdfContent,
  videoContent,
  externalUrl,
  image,
  file,
}: IKnowledgebaseArticle) => {
  if (content) return 'html';

  if (pdfContent) return 'pdf';

  if (videoContent) return 'video';

  if (externalUrl) return 'link';

  if (image) return 'jpeg';

  return file?.type;
};
