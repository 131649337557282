import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'gatsby-theme-material-ui';

import BocLogo from '../BocLogo';
import Search from '../Search';
import { ALGOLIA_COMMON_INDEX_NAME } from '../../utils/constants';
import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    maxHeight: 'none',
    margin: '0 0 0 50px',
    backgroundColor: colors.mainBg,
  },
}));

interface MobileMenuProps {
  paths: {
    link: string;
    label: string;
  }[];
  canSearch: boolean;
}

const MobileMenu = ({ canSearch, paths }: MobileMenuProps) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const closeMenu = () => setOpen(false);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4}>
          <Link to="/">
            <BocLogo />
          </Link>
        </Grid>
        <Grid item xs>
          <Box
            width="100%"
            height="100%"
            borderBottom={`2px solid ${colors.grey}`}
            display="flex"
            alignItems="center"
            pl={2}
            pb={1}
          >
            <Typography variant="h3" color="primary">
              MY AGENT PORTAL
            </Typography>
          </Box>
        </Grid>
        {paths.length > 0 && (
          <Grid item>
            <IconButton
              color="primary"
              aria-label="Menu"
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Dialog
              fullWidth
              open={isOpen}
              onClose={closeMenu}
              classes={{ paper: classes.paper }}
            >
              <Box pl={4}>
                <Grid container direction="column">
                  <Grid container item justifyContent="flex-end">
                    <IconButton
                      color="secondary"
                      onClick={() => setOpen(false)}
                      aria-label="Close"
                    >
                      <CloseIcon color="primary" />
                    </IconButton>
                  </Grid>
                  {paths.map(({ link, label }) => (
                    <Grid item key={link} onClick={closeMenu}>
                      <Link to={link}>
                        <Typography
                          variant="body2"
                          color="primary"
                          component="div"
                        >
                          <Box my={1.5} fontWeight="bold">
                            {label}
                          </Box>
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Dialog>
          </Grid>
        )}
      </Grid>
      {canSearch && (
        <Grid item>
          <Search indexName={ALGOLIA_COMMON_INDEX_NAME} />
        </Grid>
      )}
    </Grid>
  );
};

export default MobileMenu;
